
import './App.css';
import WritingArea from './Components/WritingArea';
//import Header from './Components/Header';
function App() {
  return (
    <div>
    
     <WritingArea></WritingArea>
    </div>
  );
}

export default App;
